import React from "react";
import PropTypes from "prop-types";
import { HeroHomeWrapper, CallToActionBox } from "./elements";
import { H4Strong, H1, H3Strong, H3Italic, H4Italic } from "./../Typography";
import Img from "gatsby-image";
// import { useSwipeable, SwipeableHandlers } from "react-swipeable";

// TODO: https://codesandbox.io/s/lrk6955l79?file=/src/Carousel.js
/**
 *
 * Images are mocked in this! I don't know how they're coming in from Craft. Right now this just takes an element
 *
 * The carousel does not autoscroll right now. Should it be draggable?
 *
 */
const HomeHero = ({
  heros,
  onCallToAction,
  afterPerspectives,
  isPerspectives,
}) => {
  const theHeros = heros.filter((x) => x !== null);
  // console.log(heros.map((x) => x.bannerGraphic[0].wideImage));
  const [currentSlide, setCurrentSlide] = React.useState(0);

  React.useEffect(() => {
    const next = (currentSlide + 1) % theHeros.length;
    const id = setTimeout(() => setCurrentSlide(next), 3500);
    return () => clearTimeout(id);
  }, [currentSlide]);

  return (
    <HeroHomeWrapper
      afterPerspectives={afterPerspectives || false}
      className={isPerspectives ? "isPerspectives" : ""}
    >
      <div>
        <div
          style={{
            width: `${100 * theHeros.length}%`,
            transform: `translateX(-${
              (100 * currentSlide) / theHeros.length
            }%)`,
          }}
        >
          {theHeros.map((hero, index) => {
            const myLink = `/${
              hero.typeHandle === "programmes" ? "programme" : hero.typeHandle
            }/${hero.slug}/`;
            
            const external=hero.externalLink;
            return (
              <div
                key={index}
                style={{ color: hero.bannerColor || "var(--white)" }}
              >
                {hero.bannerGraphic.length &&
                hero.bannerGraphic[0].wideImage ? (
                  hero.bannerGraphic[0].wideImage.childImageSharp ? (
                    <Img
                      fixed={
                        hero.bannerGraphic[0].wideImage.childImageSharp.fixed
                      }
                      className="desktop"
                    />
                  ) : (
                    <img
                      src={hero.bannerGraphic[0].wideImage.publicURL}
                      className="desktop"
                    />
                  )
                ) : (
                  <div className="placeholder desktop" />
                )}
                {hero.bannerGraphicPortrait &&
                hero.bannerGraphicPortrait.length &&
                hero.bannerGraphicPortrait[0].tallImage ? (
                  hero.bannerGraphicPortrait[0].tallImage.childImageSharp ? (
                    <Img
                      fixed={
                        hero.bannerGraphicPortrait[0].tallImage.childImageSharp
                          .fixed
                      }
                      className="mobile"
                    />
                  ) : (
                    <img
                      src={hero.bannerGraphicPortrait[0].tallImage.publicURL}
                      className="mobile"
                    />
                  )
                ) : (
                  <div className="placeholder mobile" />
                )}
                <div>
                  {isPerspectives ? null : (
                    <H4Strong>{hero.bannerTopline}</H4Strong>
                  )}
                  <H1>{hero.bannerTitle}</H1>
                  {isPerspectives ? null : (
                    <React.Fragment>
                      <H3Strong>{hero.bannerSubtitleLine1}</H3Strong>
                      <H3Italic>{hero.bannerSubtitleLine2}</H3Italic>
                      <CallToActionBox
                        color={hero.bannerColor || "var(--white)"}
                      >
                        <H4Italic>
                        {hero.openExternalLink==true && hero.externalLink.length>0 ? (
                          <a
                            href={external}
                            target="_blank"
                          >
                            {hero.bannerCallToAction || "Find out more"}
                          </a>
                        ) : (
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              onCallToAction(myLink);
                            }}
                          >
                            {hero.bannerCallToAction || "Find out more"}
                          </a>
                        )}
                          
                        </H4Italic>
                      </CallToActionBox>
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {theHeros.length > 1 ? (
        <nav>
          <ul>
            {theHeros.map((hero, index) => (
              <li
                className={currentSlide === index ? "selected" : ""}
                key={index}
              >
                <a
                  aria-hidden
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentSlide(index);
                  }}
                >
                  {index}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
    </HeroHomeWrapper>
  );
};

export default HomeHero;

HomeHero.propTypes = {
  heros: PropTypes.arrayOf(
    PropTypes.shape({
      bannerGraphic: PropTypes.arrayOf(
        PropTypes.shape({ url: PropTypes.string })
      ),
      bannerTopline: PropTypes.string,
      bannerTitle: PropTypes.string,
      bannerSubtitleLine1: PropTypes.string,
      bannerSubtitleLine2: PropTypes.string,
      bannerCallToAction: PropTypes.string,
      slug: PropTypes.string,
      bannerColor: PropTypes.string,
    })
  ),
  onCallToAction: PropTypes.func,
};