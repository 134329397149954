import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import HomeHero from "./../components/HomeHero";
import Perspectives from "./../components/Perspectives";
import ProgrammeBody from "./../components/ProgrammeBody";
import ArticleFilter from "./../components/ArticleFilter";

// maybe they want the four sections in perspectives boxes

const PerspectivesPage = () => {
  const perspectivesQuery = useStaticQuery(graphql`
    query {
      craftPerspectivesPerspectivesEntry {
        title
        subtitle
        seoTitle
        seoDescription
        bannerGraphic {
          ... on Craft_eventsAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        bannerGraphicPortrait {
          ... on Craft_eventsAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 800) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 800) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 800) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        perspectives {
          slug
          title
          typeHandle
          ... on Craft_articles_words_Entry {
            editorialAuthor
            rectangleThumbnails{
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            editorialAuthor
            rectangleThumbnails{
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            editorialAuthor
            rectangleThumbnails{
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_sounds_Entry {
            rectangleThumbnails{
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_event_event_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).craftPerspectivesPerspectivesEntry;
  // console.log(frontQuery);
  const {
    title,
    subtitle,
    bannerGraphic,
    bannerGraphicPortrait,
    seoTitle,
    seoDescription
  } = perspectivesQuery;
  const thisPerpectives = perspectivesQuery.perspectives.length
    ? perspectivesQuery.perspectives
    : null;
   var PersseoTitle = perspectivesQuery.seoTitle?perspectivesQuery.seoTitle:null;
   var PersDescTitle = perspectivesQuery.seoDescription?perspectivesQuery.seoDescription:null;
  
  return (
    <Layout
      backgroundColor={"var(--black)"}
      skipHeader
      title={PersseoTitle}
      SEODescription={PersDescTitle}
    >
      <HomeHero
        heros={[
          {
            bannerTitle: title,
            bannerGraphic: bannerGraphic,
            bannerGraphicPortrait: bannerGraphicPortrait,
          },
        ]}
        isPerspectives
      />
      <ProgrammeBody>
        <Perspectives tiles={thisPerpectives} title={subtitle} onTop={true} />
        <ArticleFilter
          backgroundColor={"var(--white)"}
          accentColor={"var(--purple)"}
          isFront
        />
      </ProgrammeBody>
    </Layout>
  );
};

export default PerspectivesPage;
