import styled from "styled-components";

export const HeroHomeWrapper = styled.div`
  --imageWidth: var(--width);
  --headerHeight: 800px;
  width: var(--imageWidth);
  height: var(--headerHeight);
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  --titleDrop: 200px;
  --titleFontSize: 110px;
  --calloutDrop: 125px;
  position: fixed;
  position: ${(props) => (props.afterPerspectives ? "absolute" : "fixed")};
  margin-left: ${(props) =>
    props.afterPerspectives
      ? "calc(calc(0px - var(--outerMargin)) - calc(calc(100vw - var(--width)) / 2))"
      : "auto"};
  & .mobile {
    display: none !important;
  }
  & + div {
    --headerHeight: 800px;
    margin-top: calc(
      var(--headerHeight) + 50px
    ); /* where is that 50px coming from? */
    margin-top: var(--headerHeight);
  }
  & > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    & > div {
      display: flex;
      transition: 1s;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      height: 100%;
      & > div {
        width: var(--imageWidth);
        height: 800px;
        flex: 1;
        position: relative;
        overflow: hidden;
        & > img,
        & > .gatsby-image-wrapper,
        & > .placeholder {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          min-width: 100%;
          height: 100%;
          min-height: 100%;
          z-index: 1;
          overflow: hidden;
          & + div {
            /* the text section */
            user-select: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 100vw;
            height: 100%;
            z-index: 2;
            padding: var(--titleDrop) var(--outerMargin) 0 var(--outerMargin);
            background-blend-mode: multiply;
            background-image: linear-gradient(
              to top,
              rgba(16, 24, 32, 0.25),
              rgba(16, 24, 32, 0)
            );
            & h1 {
              font-size: var(--titleFontSize);
              margin-top: 30px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  &.isPerspectives {
    & > div > div > div > div + div {
      mix-blend-mode: difference;
      padding: var(--outerMargin);
      display: flex;
      /* justify-content: center; */
      align-items: center;
      & > h1 {
        transition: 0.25s;
        padding-left: 140px;
        font-size: 11.75vw;
        margin: 0;
        white-space: nowrap;
        @media (max-width: 1023px) {
          padding-left: 0;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  & > nav {
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    position: absolute;
    user-select: none;

    bottom: 50px;
    @media (max-height: 800px) {
      position: fixed;
      bottom: 75px;
    }
    & ul {
      list-style-type: none;
      display: inline-flex;
      margin: 0;
      padding: 0;
      & li {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        transition: 0.5s;
        background-color: var(--white);
        &.selected {
          width: 36px;
        }
        & + li {
          margin-left: 10px;
        }
        & a {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: transparent;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    --imageWidth: 1440px;
    --titleDrop: 150px;
    --outerMargin: 50px;
  }
  @media (max-width: 1023px) {
    --outerMargin: 25px;
    --titleFontSize: 80px;
  }
  @media (max-width: 767px) {
    --titleFontSize: 44px;
    --calloutDrop: 50px;
    & .desktop {
      display: none !important;
    }
    & .mobile {
      display: inline-block !important;
    }
    & h3 {
      font-size: 22px;
    }
    & > nav {
      bottom: 25px;
    }
  }
  @media (min-width: 1441px) {
    --width: 100vw;
    --headerHeight: 55.555vw;
    & > div > div > div {
      height: var(--headerHeight);
    }
    & .gatsby-image-wrapper {
      height: var(--headerHeight) !important;
    }
    & + div {
      --headerHeight: 55.555vw;
    }
  }
`;

export const CallToActionBox = styled.div`
  --color: ${(props) => props.color || "var(--white)"};
  display: inline-block;
  border: 1px solid var(--color);
  margin-top: var(--calloutDrop);
  & a {
    color: var(--color);
    display: inline-block;
    padding: 14px 24px;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`;
